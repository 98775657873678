<template>
  <div id="app">
    <div id="content">
      <!-- 顶部导航 -->
      <Nav></Nav>
      <router-view />
    </div>
    <!-- 底部导航 -->
    <Footer></Footer>

    <!-- 浮窗 -->
    <FloatingWindow></FloatingWindow>
  </div>
</template>
<script>
import Nav from "./components/nav/index.vue";
import Footer from "./components/footer/index.vue";
import FloatingWindow from "./components/floatingWindow/index.vue";
export default {
  data() {
    return {
      popShow: false
    }
  },
  components: {
    Nav,
    Footer,
    FloatingWindow,
  },

}
</script>


<style lang="scss">
#content {
  width: 1600px;
  margin: 0 auto;
}
</style>
