<template>
  <div class="nav">
    <div class="nav-content">
    <!-- logo -->
    <h1 class="logo" @click="jumpHome()"></h1>
    <!-- nav列表 -->
    <ul class="nav-list">
      <li class="nav-list-li" v-for="item in navList" :key="item.title" >
        <span class="nav-list-span">
          <router-link class="nav-list-a" :to="item.path" :class="navPath.findIndex(name=>{return name == item.title}) !=-1  ? 'active':''">
            {{ item.title }}
          </router-link>
        </span>
        <!-- 判断是否有子导航 -->
        <ul
          v-if="!item.childList || item.childList.length > 0"
          :class="{
            intellect: item.title == '智能硬件',
            childList: item.title == '关于森越',
          }"
        >
          <li
            class="nestedMenu"
            v-for="items in item.childList"
            :key="items.childName"
          >
            <router-link class="nestedMenu-a" :to="items.path">
              <img
                v-if="items.img"
                :src="items.img"
                class="nestedMenu-img"
              ></img>
              <span class="nav-childList-span" :class="navPath.findIndex(name=>{return name == items.childName}) !=-1  ? 'active':''"> {{ items.childName }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  </div>
</template>

  <script>
export default {
  data() {
    return {
      // 当前路由
      navPath: [],
      // nav列表
      navList: [
        {
          title: '首页',
          path: '/',
        },
        {
          title: '关于森越',
          path: '/about',
          childList: [
            {
              childName: '公司简介',
              path: '/about/article'
            },
            {
              childName: '森越动态',
              path: '/about/trends'
            },
            {
              childName: '联系我们',
              path: '/about/contact'
            }
          ]
        },
        {
          title: '解决方案',
          path: "/solution",
        },
        {
          title: '智能硬件',
          path: "/good",
          childList: [
            {
              childName: '智慧屏',
              img: require('../../assets/picture/智能硬件_导航_智慧屏.jpg'),
              path: "/good/smartScreen",

            },
            {
              childName: '电子价签',
              img: require('../../assets/picture/智能硬件_导航_电子价签.jpg'),
              path: "/good/priceTags",

            },
            {
              childName: '手机防盗器',
              img: require('../../assets/picture/智能硬件_手机防盗器_侧面.png'),
              path: "/good/burglarAlarm",
            },
            {
              childName: '拾音器',
              img: require('../../assets/picture/智能硬件_导航_拾音器.jpg'),
              path: "/good/pickup",
            },
          ]
        },
        {
          title: '加入森越',
          path: "/joinsenyue",
        },
      ]
    }
  },
  methods: {
    jumpHome() {
      this.$router.push("/")
    }
  },
  watch: {
    $route(to, from) {
      this.navPath = to.matched.map(item => {
        return item.name
      });
    },
    deep: true, // 深度监听
    immediate: true, // 第一次初始化渲染就可以监听到
  }
}
  </script>

<style lang='scss' scoped>
a {
  text-decoration: none;
  color: #333;
}
.nav {
  position: sticky;
  top: 0;
  left: 0;
  transform: scaleX(1.6);
  z-index: 9999;
  height: 64px;
  width: 100%;
  background-color: #fff;
  padding-left: 90px;
}

.nav-content {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: -22px;
  transform: scaleX(0.61);
  width: 1200px;
}
.logo {
  z-index: 999;
  width: 120px;
  height: 40px;
  background: url("../../assets/picture/森越logo.png");
  background-size: cover;
}
.nav-list {
  display: flex;
  margin-left: 60px;
  height: 64px;
  .nav-list-li {
    margin-right: 40px;
    text-align: center;
    .nav-list-a {
      font-family: "SourceHanSansCN-Bold";
      font-size: 20px;
      font-weight: 700;
      line-height: 64px;
    }
    // 触碰子菜单
    &:hover .childList {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    &:hover .intellect {
      display: flex;
    }
    // 关于森越的子菜单
    .childList {
      z-index: 99;
      position: absolute;
      top: 100%;
      /*left: 220px;*/
      left: 240px;
      background-color: #ccc;
      display: none;
      width: 120px;
      height: 130px;
      background-image: linear-gradient(180deg, #e1e1e1 0%, #ffffff 11%);
      .nestedMenu {
        text-align: center;
        margin-top: 5px;
      }
      .nav-childList-a {
        font-weight: 500;
        display: inline-block;
        height: 100%;
        margin: 10px 0;
        &:hover {
          display: inline-block;
        }
      }
    }
    // 智能硬件的子菜单

    .intellect {
      position: absolute;
      left: 210px;
      top: 100%;
      z-index: 99;
      display: flex;
      display: none;
      justify-content: center;
      align-content: center;
      width: 100%;
      height: 240px;
      transform: scaleX(1.99);
      background-image: linear-gradient(180deg, #e1e1e1 0%, #ffffff 11%);
      margin-left: 5px;
      .nestedMenu {
        transform: scaleX(0.5);
        margin-right: 98px;
      }
      .nestedMenu,
      .nestedMenu-a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 100%;
        &:hover .nav-childList-span {
          color: #0861e4;
        }
      }
      .nav-childList-span {
        font-weight: 500;
      }

      .nestedMenu-img {
        margin-bottom: 35px;
        width: 120.53px;
        height: 120px;
      }
    }
  }
  // 选中的路由
  .active {
    color: #0861e4;
  }
}
</style>
