import "element-ui/lib/theme-chalk/index.css";

import {
    Button,
    Carousel,
    CarouselItem,
    Menu,
    MenuItem,
    Submenu,
    Image,
    Icon,
    Input,
    ButtonGroup,
    Breadcrumb,
    BreadcrumbItem,
    Pagination

} from 'element-ui'



const coms = [
    Button,
    Carousel,
    CarouselItem,
    Menu,
    MenuItem,
    Submenu,
    Image,
    Icon,
    Input,
    ButtonGroup,
    Breadcrumb,
    BreadcrumbItem,
    Pagination
]



export default {
    install(Vue, options) {
        coms.map(c => {
            Vue.component(c.name, c)
        })
    }
}
