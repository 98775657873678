<template>
  <div class="floating-window" id="menu">
    <div class="customer-service" @click="popContactState">
      <span class="customer-service-icon"></span>
      <span>森越客服</span>
    </div>
    <div class="wx">
      <span class="wx-icon" @click="popWXState"></span>
      <span>微信</span>
    </div>
    <!-- 联系弹窗 -->
    <div class="contact-pop" v-show="popContacOpen">
      <div class="contact-pop-top">
        <!-- 收起弹窗icon -->
        <span class="contact-pop-down" @click="popContactState"></span>
        <!-- 公司信息 -->
        <div class="contact-info">
          <img class="contact-info-img" src="../../assets/icon/logo_圆.png" />
          <div class="contact-info-txt">
            <p>广东森越互联网科技有限公司</p>
            <p>售后热线：020-32038032</p>
          </div>
        </div>
      </div>
      <!-- 添加联系方式 -->
      <div class="contact-pop-content">
        <div class="contact-info-form">
          <p>请输入您的联系方式：</p>
          <el-input v-model="contactInfo"></el-input>
        </div>
        <el-button round @click="sendMsg">点击提交</el-button>
        <p class="time">受理时间：工作日09:00-18:00 节假日延后</p>
      </div>
    </div>
    <!-- 微信弹窗 -->
    <div class="wxPop" v-show="popWxOpen"></div>
  </div>
</template>

<script>

  import {sendCustomer} from "@/api/allapi";

export default {
  data() {
    return {
      // 客服弹窗状态
      popContacOpen: false,
      // 微信弹窗状态
      popWxOpen: false,
      //   联系方式
      contactInfo: ''
    }
  },
  mounted() {
    //点击任意位置关闭区域弹窗
    document.addEventListener('click', (e) => {

      //获取弹窗对象
      const userCon = document.getElementById('menu');
      //判断弹窗对象中是否包含点击对象
      if (userCon && !userCon.contains(e.target)) {
        this.popContacOpen = false
        this.popWxOpen = false
      }
    })
  },
  methods: {

    sendMsg(){
      sendCustomer({
        "customerPhone": this.contactInfo
      }).then(res=> {
        if(res.code == 200) {
          this.popContacOpen = false
        }
      })
    },

    // 更改客服弹窗状态
    popContactState() {
      this.popContacOpen = !this.popContacOpen
      this.contactInfo = ''
    },
    // 更改微信弹窗状态
    popWXState() {
      this.popWxOpen = !this.popWxOpen

    }
  },

}
</script>

<style lang="scss" scoped>
.floating-window {
  z-index: 999;
  position: fixed;
  float: right;
  right: 0;
  top: 60%;
  width: 50px;
  height: 140px;
  background: url("../../assets/icon/侧边_bg.png");
  background-size: cover;
  border-radius: 6px 0px 0px 6px;
  background-color: #fff;

  .customer-service,
  .wx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      margin-top: 8px;
      font-size: 8px;
      color: #ffffff;
    }
  }
  // 客服
  .customer-service {
    width: 100%;
    height: 70px;
    .customer-service-icon {
      display: inline-block;
      background: url("../../assets/icon/icon_客服.png");
      background-size: cover;
      width: 20px;
      height: 23px;
    }
    span {
      display: inline-block;
      width: 100%;
      font-size: 8px;
      text-align: center;
    }
  }
  //   微信
  .wx {
    height: 70px;
    .wx-icon {
      display: inline-block;
      background: url("../../assets/icon/icon_微信.png");
      background-size: cover;
      width: 24px;
      height: 20px;
    }
  }

  //联系信息弹窗
  .contact-pop {
    position: absolute;
    left: -380px;
    top: -110px;
    width: 360px;
    height: 341px;
    background-color: #fff;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.36);
    //联系信息顶部
    .contact-pop-top {
      position: relative;
      padding: 15px 10px 15px 40px;
      width: 360px;
      height: 80px;
      background: url("../../assets/icon/弹窗_bg.png");
      background-size: cover;
      .contact-info {
        display: flex;
        align-items: center;
        .contact-info-txt {
          margin-left: 10px;
          p {
            color: #fff;
            font-size: 14px;
          }
          :nth-child(2) {
            margin-top: 8px;
          }
        }
        .contact-info-img {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }

      //收起弹窗icon
      .contact-pop-down {
        position: absolute;
        right: 10px;
        top: 7px;
        transform: rotate(-225deg);
        border: 2px solid rgba(255, 255, 255, 1);
        border-bottom: none;
        border-left: none;
        width: 10px;
        height: 10px;
      }
    }
    //联系信息内容
    .contact-pop-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 360px;
      height: 260px;
      background-color: #fff;
      //添加联系input
      .contact-info-form {
        margin-top: 30px;
        width: 289px;
        p {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
      ::v-deep .el-button {
        width: 150px;
        height: 40px;
        border: 1px solid rgba(8, 97, 228, 1);
        border-radius: 20px;
        background-color: none;
        margin: 10px 0 20px 0;
        padding: 12px 23px;
        width: auto;
        overflow: visible;
        span {
          color: #0861e4;
          font-size: 14px;
        }
      }
      .time {
        font-size: 14px;
        color: #999999;
      }
    }
  }
  //微信弹窗
  .wxPop {
    position: absolute;
    left: -130px;
    top: 60px;
    display: inline-block;
    width: 135px;
    height: 120px;
    background: url("../../assets/picture/右侧边_二维码.png");
    background-size: cover;
  }
}
</style>
