import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import "@/assets/css/index.css";
import element from './assets/elmui/index.js'
import './utils/setRem'

Vue.use(element)

Vue.config.productionTip = false



// 解决路由重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


// setRem()
// window.addEventListener("resize", setRem); // 窗口大小改变时重新计算
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
