import request from '@/utils/request'

//获取轮播图
export function getCarouselMap() {
  return request({
    url: '/system/carouselMap/front',
    method: 'get'
  })
}

//招聘信息
export function listAdvertise(query) {
  return request({
    url: '/system/advertise/front',
    method: 'post',
    data: query
  })
}

//新闻列表
export function listNews(query) {
  return request({
    url: '/system/news/front',
    method: 'post',
    data: query
  })
}

//新闻详情
export function getNewInfo(data) {
  return request({
    url: '/system/news/frontInfo/' + data,
    method: 'get'
  })
}

// 招聘详情
export function getAdvertiseInfo(data) {
  return request({
    url: '/system/advertise/front/' + data,
    method: 'get'
  })
}

//客户质询
export function sendCustomer(query) {
  return request({
    url: '/system/customerServ/send',
    method: 'post',
    data: query
  })
}


export function getContactUsInfo() {
  return request({
    url: '/system/contactUs/front',
    method: 'get'
  })
}


export function getCompanyquaArr() {
  return request({
    url: '/system/companyquaarr/front',
    method: 'get'
  })
}

export function getCompanyquaMap() {
  return request({
    url: '/system/companyquamap/front',
    method: 'get'
  })
}
