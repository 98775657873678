<template>
  <div class="foot">
    <div class="foot-content">
      <div class="foot-top">
        <div class="foot-left">
          <!-- 特色产品 -->
          <div class="foot-mode">
            <p class="foot-left-title">特色产品</p>
            <ul class="foot-left-list">
              <li class="cursor_new" @click="pushRoute('/good/smartScreen')">智慧屏</li>
              <li class="cursor_new" @click="pushRoute('/good/priceTags')">电子价签</li>
              <li class="cursor_new" @click="pushRoute('/good/burglarAlarm')">手机防盗器</li>
              <li class="cursor_new" @click="pushRoute('/good/pickup')">拾音器</li>
            </ul>
          </div>
          <!--  合作伙伴-->
          <div class="foot-mode">
            <p class="foot-left-title">合作伙伴</p>
            <div class="foot-left-flex">
              <ul class="foot-left-list">
                <li>中国移动</li>
                <li>中国联通</li>
                <li>中国电信</li>
                <li>中国邮政</li>
              </ul>
              <ul class="foot-left-list">
                <li>中国铁塔</li>
                <li>中国邮政</li>
                <li>国家电网</li>
                <li>更多···</li>
              </ul>
            </div>
          </div>
          <!--  联系我们-->
          <div class="foot-mode">
            <p class="foot-left-title">联系我们</p>
            <ul class="foot-left-list contact-us-ul">
              <li><span>联系电话</span><span>{{info.telphone}}</span></li>
              <li><span>商务合作</span><span>{{info.cooperation}}</span></li>
              <li><span>总部地址</span><span>{{info.address}}</span></li>
            </ul>
          </div>
        </div>
        <div class="foot-right">
          <img class="QR-code-img" :src="imageBaseUrl + info.wecatImage" />
          <p>联系我们</p>
        </div>
      </div>
      <div class="foot-bottom">
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2023036940号</a> Copyright©2008-2024 版权所有
          广东森越互联网科技有限公司
        </p>
<!--        <p>-->
<!--          <span class="filings"></span>-->
<!--          粤公网安备 44010602000XXXX号-->
<!--        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
  import { getContactUsInfo } from "@/api/allapi";
export default {
  data() {
    return {
      info: {},
      imageBaseUrl: "https://backend-image.obs.cn-south-1.myhuaweicloud.com/test/",
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      getContactUsInfo().then(res=> {
        this.info = res.data
      })
    },
    pushRoute(url) {
      this.$router.push(url)
    }
  }

}
</script>

<style lang="scss" scoped>
.foot {
  margin-top: 50px;
  padding: 40px 0 20px 0;
  background: #f5f5f5;
}
.foot-content {
  margin: 0 auto;
  width: 1600px;
}
.foot-top {
  display: flex;
  justify-content: space-between;
  .foot-mode {
    margin-right: 60px;
  }

  //左边部分
  .foot-left {
    display: flex;
  }

  .foot-left-title {
    font-weight: 700;
    font-size: 17px;
    color: #333;
    margin-bottom: 30px;
  }
  .foot-left-flex {
    display: flex;
    :nth-child(1) li {
      margin-right: 24px;
    }
  }
  .foot-left-list {
    li {
      margin-bottom: 14px;
      color: #666;
      font-size: 14px;
    }
  }

   .cursor_new{
      cursor: pointer;
  }

  // 联系我们 ul
  .contact-us-ul li {
    color: #666;
    font-size: 14px;
    span {
      color: #666;
      font-size: 14px;
    }
    :first-child {
      margin-right: 10px;
    }
  }
  .foot-left-list {
    li {
      margin-bottom: 14px;
      color: #666;
      font-size: 14px;
    }
  }
  // 联系我们 ul
  .contact-us-ul li {
    :first-child {
      margin-right: 10px;
    }
  }
  // 右边部分
  .foot-right {
    p {
      text-align: center;
      font-size: 14px;
      color: #666666;
    }
    .QR-code-img {
      width: 120px;
      height: 120px;
    }
  }
}

//底部部分
.foot-bottom {
  text-align: center;
  color: #666;
  font-size: 16px;
  .filings {
    display: inline-block;
    background: url("../../assets/icon/备案_logo.jpg");
    background-size: cover;
    width: 26px;
    height: 28px;
    vertical-align: middle;
  }
  p {
    color: #666;
  }
  :nth-child(1) {
    margin-bottom: 6px;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
}
</style>
