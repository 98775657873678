import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "首页",
    component: () => import('@/views/home/index.vue'),
  },
  // 关于森越
  {
    path: "/about",
    name: "关于森越",
    component: () => import('@/views/about/index.vue'),
    redirect: '/about/article', //重定向
    children: [
      {
        path: "/about/article",
        name: "公司简介",
        component: () => import('@/views/about/article/index.vue'),
      },

      {
        path: "/about/contact",
        name: "联系我们",
        component: () => import('@/views/about/contact/index.vue'),
      },
      {
        path: "/about/trends",
        name: "森越动态",
        component: () => import('@/views/about/trends/index.vue'),
      },
      {
        path: "/about/trends/details",
        name: "文章内容",
        component: () => import('@/views/about/trends/details/index.vue'),
      }
    ]
  },
  // 商品/业务
  {
    path: "/good",
    name: "智能硬件",
    component: () => import('@/views/good/index.vue'),
    redirect: '/good/hardware', //重定向
    children: [
      {
        path: "/good/hardware",
        component: () => import('@/views/good/hardware/index.vue'),
      },
      {
        path: "/good/smartScreen",
        name: "智能屏",
        component: () => import('@/views/good/smartScreen/index.vue'),
      },
      {
        path: "/good/priceTags",
        name: "电子标签",
        component: () => import('@/views/good/priceTags/index.vue'),
      },
      {
        path: "/good/burglarAlarm",
        name: "手机防盗器",
        component: () => import('@/views/good/burglarAlarm/index.vue'),
      },
      {
        path: "/good/pickup",
        name: "拾音器",
        component: () => import('@/views/good/pickup/index.vue'),
      },
    ]
  },
  // 加入森越
  // 加入森越
  {
    path: "/joinsenyue",
    name: "加入森越",
    redirect: '/joinsenyue/recruitList', //重定向
    component: () => import('@/views/joinSenyue/index.vue'),
    children: [
      {
        path: "/joinsenyue/recruitList",
        component: () => import('@/views/joinSenyue/recruitList/index.vue'),
      },
      {
        path: "/joinsenyue/details",
        name: "招聘内容",
        component: () => import('@/views/joinSenyue/details/index.vue'),
      },
    ]
  },
  // 解决方案
  {
    path: "/solution",
    name: "解决方案",
    component: () => import('@/views/solution/index.vue'),
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 兼容IE
  window.scrollTo(0, 0);
  next();
})


export default router
